//@ts-check

/**
 * @enum {'Idle' | 'Queued' | 'ShouldRun' | 'Running' | 'Success' | 'Fail' | 'Skip'}
 */
export const Status = {
  /** @type {'Idle'} */ Idle: 'Idle',
  /** @type {'Queued'} */ Queued: 'Queued',
  /** @type {'ShouldRun'} */ ShouldRun: 'ShouldRun',
  /** @type {'Running'} */ Running: 'Running',
  /** @type {'Success'} */ Success: 'Success',
  /** @type {'Fail'} */ Fail: 'Fail',
  /** @type {'Skip'} */ Skip: 'Skip',
};

/**
 * @enum {'V2' | 'V3' | 'Auto'}
 */
export const AuthVersion = {
  /** @type {'V2'} */ V2: 'V2',
  /** @type {'V3'} */ V3: 'V3',
  /** @type {'Auto'} */ Auto: 'Auto',
};

/**
 * @enum {'md5' | 'sha' | 'sha224' | 'sha256' | 'sha384' | 'sha512'}
 */
export const AuthProtocol = {
  /** @type {'md5'} */ md5: 'md5',
  /** @type {'sha'} */ sha: 'sha',
  /** @type {'sha224'} */ sha224: 'sha224',
  /** @type {'sha256'} */ sha256: 'sha256',
  /** @type {'sha384'} */ sha384: 'sha384',
  /** @type {'sha512'} */ sha512: 'sha512',
};

/**
 * @enum {'des' | 'aes' | 'aes256b' | 'aes256r'}
 */
export const PrivProtocol = {
  /** @type {'des'} */ des: 'des',
  /** @type {'aes'} */ aes: 'aes',
  /** @type {'aes256b'} */ aes256b: 'aes256b',
  /** @type {'aes256r'} */ aes256r: 'aes256r',
};
