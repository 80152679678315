import { useSuite } from './useSuite';

/**
 * @param {React.Ref<any>} ref
 * @param {{
 *  onSuccess?: () => void,
 *  onFail?: () => void,
 * }} props
 * @param {Array<
 *  (props: {
 *     key: string;
 *     ref: React.Ref<any>;
 *     onSuccess?: () => void;
 *     onFail?: () => void;
 *   }) => JSX.Element
 * >} steps
 */
export const useSimpleSuite = (ref, props, steps) =>
  useSuite(
    ref,
    props,
    steps.map((getStep) => ({ getStep }))
  );
