import React from "react";
import * as ra from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
	SearchOutlined as GraphIcon,
	CompareOutlined as DiffIcon,
	DeviceUnknown as DeviceUnknownIcon,
  DynamicFeed as TriggerMetricsIcon
} from "@material-ui/icons/";

const theme = {
	palette: {
		primary: {
			main: "#990ae3",
		},
	},
	overrides: {
		MuiAppBar: {
			colorSecondary: {
				color: "rgba(0, 0, 0, 0.54)",
				backgroundColor: "#fff",
			},
		},
	},
};

const useStyles = makeStyles({
	title: {
		flex: 1,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
	},
	spacer: {
		flex: 1,
	},
});

const AppBar = (props) => {
	const classes = useStyles();

	return (
		<ra.AppBar {...props}>
			<Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
			<span style={{ flex: 1 }} />
			<img src="/logo.png" alt="logo" style={{ height: 24, marginRight: 10 }} />
		</ra.AppBar>
	);
};

const Menu = (props) => (
	<>
		<ra.Menu {...props} />
		<ra.MenuItemLink to="/graph" primaryText="Graph" leftIcon={<GraphIcon />} />
		<ra.MenuItemLink to="/diff" primaryText="Metrics diff" leftIcon={<DiffIcon />} />
		<ra.MenuItemLink to="/device-debug" primaryText="Debug device" leftIcon={<DeviceUnknownIcon />} />
		<ra.MenuItemLink to="/trigger-metrics" primaryText="Trigger Metrics" leftIcon={<TriggerMetricsIcon />} />
	</>
);

const Layout = (props) => <ra.Layout {...props} appBar={AppBar} theme={theme} menu={Menu} />;

export default Layout;
