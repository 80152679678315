import React from "react";
import { useState, useEffect } from "react";
import { DiffEditor } from "@monaco-editor/react";
import { fetchUtils, useNotify } from "react-admin";

const DiffExpand = ({ diffTokenFail, record: { script, id } }) => {
  const notify = useNotify();
  const [prodMetric, setProdMetric] = useState("");
  
  useEffect(() => {
    async function getProdMetrics(diffUrl, diffToken) {
      try {
        const metric = await fetchJson(`${diffUrl}/metrics/${id}`, diffToken);
        setProdMetric(metric);
      } catch (err) {
        notify(`${err}`, { type: "warning" });
        diffTokenFail(true);
      }
    }
    
    const diffToken = localStorage.getItem("diffToken");
    const diffUrl = localStorage.getItem("diffUrl");
    if (diffUrl && diffToken) {
      getProdMetrics(diffUrl, diffToken);
    } else {
      diffTokenFail(true);
    }
  }, [diffTokenFail, id, notify]);

  return (
    <DiffEditor
      height={600}
      language="typescript"
      theme="vs-light"
      original={script.src}
      modified={prodMetric ? prodMetric.json.script.src : ""}
      options={{
        tabSize: 2,
        minimap: {
          enabled: false,
        },
        readOnly: true,
      }}
    />
  );
};

const fetchJson = async (path, accessToken, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  options.headers.set("Authorization", accessToken);

  const accountId = localStorage.getItem("accountId");
  if (accountId) {
    options.headers.set("Lolo-Account-Id", accountId);
  }

  return fetchUtils.fetchJson(path, options).catch((err) => {
    if (err.body && err.body.error) {
      // 401, 403, 500
      err.message = err.body.error;
    } else if (err.body && err.body.errors) {
      // 422
      err.message = err.body.errors
        .map((item) => {
          const field = item.dataPath.replace(".body.", "");
          return `${field} ${item.message}`;
        })
        .join(", ");
    }

    throw err;
  });
};

export default DiffExpand;
