import React from "react";
import { List, Card, CardHeader, ListItem, ListItemText, ListItemIcon, Checkbox, Divider } from "@material-ui/core/";
import { useStyles, intersection, not, union } from "./utils";

const CustomList = ({ title, items, checked, setChecked, readOnly }) => {
	const classes = useStyles();

	const numberOfChecked = (items) => readOnly ? 0 : intersection(checked, items).length;

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked.sort());
	};

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	return (
		<Card variant="outlined">
			<CardHeader
				titleTypographyProps={{variant:'body2' }}
				subheaderTypographyProps={{variant: 'body2'}}
				avatar={
					 (readOnly ? null :
						<Checkbox
							color="primary"
							onClick={readOnly ? () => {} : handleToggleAll(items)}
							checked={numberOfChecked(items) === items.length && items.length !== 0}
							indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
							disabled={items.length === 0 || readOnly}
							inputProps={{ "aria-label": "all items selected" }}
						/>
					)
				}
				title={title}
				subheader={readOnly ? items.length : `${numberOfChecked(items)}/${items.length} selected`}
			/>
			<Divider />
			<List className={classes.list} dense component="div" role="list">
				{items.map((value) => {
					const labelId = `transfer-list-all-item-${value}-label`;

					return (
						<ListItem key={value} role="listitem" button onClick={readOnly ? () => {} : handleToggle(value)}>
							<ListItemIcon style={{display: readOnly ? 'none' : 'initial'}}>
									<Checkbox
										color="primary"
										checked={!readOnly && checked.indexOf(value) !== -1}
										tabIndex={-1}
										disableRipple
										disabled={readOnly}
										inputProps={{ "aria-labelledby": labelId }}
									/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value} />
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Card>
	);
};

export default CustomList;
