//@ts-check
import React from 'react';
import { makeStyles, CircularProgress, Fab } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';
import LensIcon from '@material-ui/icons/Lens';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { Status } from '../enums';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    color: green[400],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  buttonSuccess: {
    backgroundColor: green[400] + '!important',
  },
  buttonFail: {
    backgroundColor: red[400] + '!important',
  },
}));

/**
 *
 * @param {{
 *  status: Status
 * }} params
 */
export const StatusIndicator = ({ status }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab
        size="small"
        disabled
        className={
          {
            Success: classes.buttonSuccess,
            Fail: classes.buttonFail,
          }[status]
        }
      >
        {(status === Status.Idle || status === Status.Queued) && (
          <RadioButtonUncheckedIcon />
        )}
        {status === Status.Success && <DoneIcon />}
        {status === Status.Fail && <CloseIcon />}
        {status === Status.Skip && <SkipNextIcon />}
        {(status === Status.Running || status === Status.ShouldRun) && (
          <>
            <LensIcon />
            <CircularProgress size={48} className={classes.progress} />
          </>
        )}
      </Fab>
    </div>
  );
};
