import React from 'react';
import * as ra from 'react-admin';
import { Grid } from '@material-ui/core';

import { LoloList } from '@lolocompany/react-admin-lolo';
import ScheduleIcon from '@material-ui/icons/AccessTimeOutlined';
import DevicesIcon from '@material-ui/icons/RouterOutlined';

const ExpandPanel = props => {
	return (
    <>
      <Grid container style={{marginBottom: 24}}>
        <Grid item xs={1} align='center'>
          <ScheduleIcon style={{marginTop: 7}} color='action'/>
        </Grid>
        <Grid item xs={11}>
          <ra.ReferenceManyField
            reference='schedules'
            target='deviceTypeId'
            perPage={100}
            filter={{ enabled: true }}
            {...props}
            >
            <ra.Datagrid rowClick='edit'>
              <ra.ReferenceField label="Metric" source="metricId" reference="metrics">
                <ra.TextField source="id" />
              </ra.ReferenceField>
              <ra.TextField source='interval' />
              <ra.DateField label='Last Poll' source="state.updatedEpoch" showTime />
            </ra.Datagrid>
          </ra.ReferenceManyField>
        </Grid>
      </Grid>
      <Grid container style={{marginBottom: 7}}>
        <Grid item xs={1} align='center'>
          <DevicesIcon style={{marginTop: -4}} color='action'/>
        </Grid>
        <Grid item xs={11}>
          <ra.ReferenceManyField
            reference='devices'
            target='deviceTypeId'
            perPage={100}
            style={{marginBottom: 0}}
            {...props}
            >
            <ra.SingleFieldList>
              <ra.ChipField source="id" />
            </ra.SingleFieldList>
          </ra.ReferenceManyField>
        </Grid>
      </Grid>
    </>
	);
};

const ListDeviceTypes = props => (
	<LoloList
		expand={ExpandPanel} {...props}
	/>
);

export default ListDeviceTypes;