//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';

const SiaStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: 'SIA data',
      action: 'sia-data',
      data: {},
      onResponse: (data, log) => {
        if (Object.keys(data.refData).length === 0) {
          log.fatal(`No SIA data for hostname "${data.hostname}"`);
          return false;
        }

        log.info(`Found SIA data for hostname "${data.hostname}"`);

        let invalidInterfaces = [];

        for (const ifName of Object.keys(data.refData)) {
          if (typeof data.refData[ifName] !== 'object') {
            // ~200 devices are using old refData structures which are not grouped by ifName
            log.info(`"${ifName}" = ${JSON.stringify(data.refData[ifName])}`);
            continue;
          }

          for (const propertyKey of Object.keys(data.refData[ifName])) {
            log.info(
              `"${ifName}"."${propertyKey}" = ${JSON.stringify(
                data.refData[ifName][propertyKey]
              )}`
            );
          }

          if (
            Object.keys(data.refData[ifName]).findIndex(
              (it) => it.toLowerCase() === 'CustomerName'.toLowerCase()
            ) === -1
          ) {
            invalidInterfaces.push(ifName);
          }
        }

        for (const ifName of invalidInterfaces) {
          log.fatal(`"${ifName}" is missing required property CustomerName`);
        }

        return invalidInterfaces.length === 0;
      },
    });
  }
);

export default SiaStep;
