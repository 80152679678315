const withId = title => {
	const create = schema => ({
	  ...schema,
	  properties: {
	    id: { type: 'string', title },
	    ...schema.properties
	  }
	});

	const edit = schema => {
		const tmpSchema = create(schema);
		tmpSchema.properties.id.readOnly = true;
		return tmpSchema;
	}

	const list = schema => {
		const tmpSchema = create(schema);
		delete tmpSchema.properties.debug;
		delete tmpSchema.properties.overrideSnmpSettings;
		return tmpSchema;
	}

	return {
		listSchemaTransform: list,
		createSchemaTransform: create,
		editSchemaTransform: edit
	};
};

export {
	withId
}