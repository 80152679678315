import React, { useState, useEffect } from "react";
import { Title, useDataProvider } from "react-admin";
import { useStyles, intersection, not } from "./utils";
import { Grid, Card, CardContent, Typography, TextField, InputAdornment } from "@material-ui/core/";
import { ArrowForwardIosOutlined, ArrowBackIosOutlined, Search } from "@material-ui/icons/";
import CustomList from "./CustomList";
import CustomIconButton from "./CustomIconButton";
import { SendBtnWithDialog } from "./SendBtnWithDialog";

const TriggerMetrics = () => {
	const dataProvider = useDataProvider();
	const classes = useStyles();

	const [checked, setChecked] = useState([]);
	const [choice, setChoice] = useState([]);
	const [chosen, setChosen] = useState([]);
	const [metricsFilter, setMetricsFilter] = useState("");
	const [filterValid, setFilterValid] = useState(true);
	const [devices, setDevices] = useState([]);
	const [schedules, setSchedules] = useState([]);
	const [affectedDevices, setAffectedDevices] = useState([]);

	const choiceChecked = intersection(checked, choice);
	const chosenChecked = intersection(checked, chosen);

	useEffect(() => {
		const isFilterValid = /^[a-zA-Z0-9_@-]*$/.test(metricsFilter);
		setFilterValid(isFilterValid);
		if (isFilterValid) {
			const filter = metricsFilter.length ? metricsFilter : ".*";

			dataProvider.sendRequest(`/metrics?pick[]=id&q[id]=${filter}&limit=all`).then((res) => {
				const { metrics } = res.data;
				const newMetrics = [...metrics.map((m) => m.id).sort()];
				setChoice(not(newMetrics, chosen));
			});
			dataProvider.sendRequest(`/devices?pick[]=id&pick[]=deviceTypeId&limit=all`).then((res) => {
				const { devices } = res.data;
				setDevices(devices);
			});
			dataProvider.sendRequest(`/schedules?pick[]=id&pick[]=deviceTypeId&pick[]=metricId&limit=all`).then((res) => {
				const { schedules } = res.data;
				setSchedules(schedules);
			});
		}
	}, [dataProvider, metricsFilter, chosen]);

	useEffect(() => {
		const deviceTypeIds = [
			...new Set(
				schedules.filter((schedule) => chosen.includes(schedule.metricId)).map((schedule) => schedule.deviceTypeId)
			),
		];
		setAffectedDevices(
			devices.filter((device) => deviceTypeIds.includes(device.deviceTypeId)).map((device) => device.id).sort()
		);
	}, [chosen, devices, schedules]);

	const handleCheckedRight = () => {
		setChosen(chosen.concat(choiceChecked).sort());
		setChoice(not(choice, choiceChecked).sort());
		setChecked(not(checked, choiceChecked).sort());
	};

	const handleCheckedLeft = () => {
		setChoice(choice.concat(chosenChecked).sort());
		setChosen(not(chosen, chosenChecked).sort());
		setChecked(not(checked, chosenChecked).sort());
	};

	const sendMetrics = async () => {
		try {
			const res = await dataProvider.sendRequest("/trigger-metrics", {
				method: "POST",
				body: JSON.stringify({ metricIds: chosen }),
			});
			setMetricsFilter("");
			setChecked([]);
			setChoice(choice.concat(chosen));
			setChosen([]);

			if (res.data && res.data.error) {
					console.error(`Request to /trigger-metrics failed: ${res.data.error}`);
			}

		} catch (err) {
			console.error(`Request to /trigger-metrics failed: ${err}`);
		}
	};

	const dialogBody = () => {
		return (
			<div>
				<ul>
					{[...affectedDevices].map((metric) => (
						<li>{metric}</li>
					))}
				</ul>
			</div>
		);
	};

	return (
		<div>
			<Typography className={classes.heading}>Trigger metrics</Typography>
			<Card>
				<Title title="Trigger metrics" />
				<CardContent>
					<Grid
						container
						spacing={2}
						direction="column"
						justifyContent="space-between"
						alignItems="space-between"
						className={classes.root}
					>
						<Grid item>
							<Grid container spacing={2} alignItems="flex-end">
								<Grid item>
									<form noValidate autoComplete="off">
										<TextField
											label="Metric name"
											variant="outlined"
											value={metricsFilter}
											error={!filterValid}
											style={{ width: 402 }}
											onChange={({ target: { value } }) => setMetricsFilter(value)}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<Search color="primary" style={{ opacity: 0.5 }} />
													</InputAdornment>
												),
											}}
										/>
									</form>
								</Grid>
								<Grid item style={{ width: 64, height: 66 }}></Grid>
								<Grid item>
									<SendBtnWithDialog
										btnTitle="Trigger metrics"
										btnStyle={{ width: 402, height: 56 }}
										btnDisabled={!chosen || !chosen.length}
										dialogTitle={`Triggering these metrics will affect following ${affectedDevices.length} devices:`}
										dialogBody={dialogBody()}
										onConfirm={sendMetrics}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container spacing={2}>
								<Grid item>
									<CustomList title="Available metrics" items={choice} checked={checked} setChecked={setChecked} />
								</Grid>
								<Grid item>
									<Grid container className={classes.listController} direction="column" alignItems="center">
										<CustomIconButton
											icon={<ArrowForwardIosOutlined />}
											disabled={choiceChecked.length === 0}
											onClick={handleCheckedRight}
										/>
										<CustomIconButton
											icon={<ArrowBackIosOutlined />}
											disabled={chosenChecked.length === 0}
											onClick={handleCheckedLeft}
										/>
									</Grid>
								</Grid>
								<Grid item>
									<CustomList title={`Chosen metrics (affects ${affectedDevices.length} devices)`} items={chosen} checked={checked} setChecked={setChecked} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</div>
	);
};

export default TriggerMetrics;
