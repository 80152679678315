//@ts-check
import React, { forwardRef, useContext } from 'react';
import useLocalStorage from 'use-local-storage';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { useActionStep } from '../hooks/useActionStep';
import { useRequest } from '../hooks/useRequest';
import { AuthVersion } from '../enums';
import { ConfigContext } from '../DeviceDebug';

const stringifyArray = (arr) => arr.map((it) => JSON.stringify(it)).join(', ');

const useStyles = makeStyles((theme) => ({
  controlForm: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
}));

const CompareSiaStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    const config = useContext(ConfigContext);
    const classes = useStyles();
    const [otherHost, setOtherHost] = useLocalStorage(
      'debug-device-compare-sia-data',
      ''
    );
    const getSiaForOtherDevice = useRequest('sia-data', {
      host: otherHost.trim(),
      auth: {
        version: AuthVersion.Auto,
      },
    });
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: 'Compare SIA data to another device',
      action: 'sia-data',
      data: {},
      onResponse: async (data, log) => {
        const other = await getSiaForOtherDevice();

        if (other.error) {
          log.fatal(`Unable to resolve SIA data for "${otherHost.trim()}"`);
          log.fatal(other.error);
          return false;
        }

        log.info(`Device A Hostname = ${JSON.stringify(config.host.trim())}`);
        log.info(`Device B Hostname ${JSON.stringify(otherHost.trim())}`);

        const refDataA = data.refData;
        const refDataB = other.data.refData;
        const ifNamesA = Object.keys(refDataA);
        const ifNamesB = Object.keys(refDataB);

        log.info(`Device A Interfaces = ${stringifyArray(ifNamesA)}`);
        log.info(`Device B Interfaces = ${stringifyArray(ifNamesB)}`);

        const propsJoined = ifNamesA.reduce((acc, it) => {
          for (const propName of Object.keys(refDataA[it])) {
            acc[propName] = [
              ...(acc[propName] || []),
              { ifName: it, device: 'A' },
            ];
          }
          return acc;
        }, {});
        for (const ifName of ifNamesB) {
          for (const propName of Object.keys(refDataB[ifName])) {
            propsJoined[propName] = [
              ...(propsJoined[propName] || []),
              { ifName, device: 'B' },
            ];
          }
        }

        for (const propName of Object.keys(propsJoined)) {
          log.info(`"${propName}" [`);
          for (const { ifName, device } of propsJoined[propName].sort(
            (a, b) => {
              if (a.ifName < b.ifName) {
                return -1;
              }
              if (a.ifName > b.ifName) {
                return 1;
              }
              return 0;
            }
          )) {
            log.info(
              `\tDevice ${device} "${ifName}" = "${
                (device === 'A' ? refDataA : refDataB)[ifName][propName]
              }"`
            );
          }
          log.info(']');
        }
      },
      children: [
        <form className={classes.controlForm} autoComplete="off">
          <TextField
            required
            label="Other Device Hostname"
            variant="outlined"
            error={otherHost.length === 0}
            value={otherHost}
            style={{ width: 400 }}
            onChange={({ target: { value } }) => setOtherHost(value)}
          />
        </form>,
      ],
    });
  }
);

export default CompareSiaStep;
