import React, { useState } from 'react';
import keyBy from 'lodash/keyBy'
import { useQuery, Datagrid, TextField, Pagination, Loading, ListContextProvider, SimpleShowLayout, DateField, useRecordContext } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import FastRewind from "@material-ui/icons/FastRewind";


export const ListMetricVersions = ({ metricId, onClick }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'metricversions',
    payload: {
      pagination: { page, perPage },
      sort: { field: 'updatedAt', order: 'DESC' },
      filter: { id: `^${metricId}$` },
    }
  });

  const showSrc = () => (
    <SimpleShowLayout>
      <TextField component={'pre'} label={'Source code'} source="script.src" />
    </SimpleShowLayout>
  );

  const ActionField = ({ source }) => {
    const record = useRecordContext();
    const handleClick = (e) => {
      e.stopPropagation();
      onClick(record.script.src);
    }
    return (
    <Tooltip title="Load source code">
      <IconButton aria-label="Load" color="primary">
        <FastRewind color="primary" size="small" variant='contained' disableElevation onClick={handleClick}/>
      </IconButton>
    </Tooltip>);
  };

  if (loading) {
    return <Loading />
  }
  if (error) {
    if(!error.body || !error.body.error){
      return <span>{`Request error (status: ${error.status || 'unknown'})`}</span>
    }
    return <span>{error.body.error}</span>
  }
  return (
    <ListContextProvider value={{
      data: keyBy(data, '_id'),
      ids: data.map(({ _id }) => _id),
      total,
      page,
      perPage,
      setPage,
      setPerPage,
      currentSort: { field: 'updatedAt', order: 'DESC' },
      basePath: 'metricversions',
      resource: 'metricversions',
      selectedIds: []
    }}>
      <Datagrid expand={showSrc} rowClick="expand">
        <TextField source="version"/>
        <DateField label="Updated" source="updatedAt" showTime />
        <ActionField label="Action" />
      </Datagrid>
      <Pagination />
    </ListContextProvider >
  )
}