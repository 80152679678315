//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';

export const SNMPv2_MIB = {
  sysDescr: '1.3.6.1.2.1.1.1.0',
  sysObjectID: '1.3.6.1.2.1.1.2.0',
  sysUpTime: '1.3.6.1.2.1.1.3.0',
  sysContact: '1.3.6.1.2.1.1.4.0',
  sysName: '1.3.6.1.2.1.1.5.0',
  sysLocation: '1.3.6.1.2.1.1.6.0',
  sysServices: '1.3.6.1.2.1.1.7.0',
};

const SnmpPollStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onFail,
      onSuccess,
      name: 'SNMP poll',
      action: 'poll-snmp',
      data: { oids: Object.values(SNMPv2_MIB) },
      onResponse: (data, log) => {
        log.info(`Using SNMP auth ${data.version}`);

        let errors = 0;

        for (const varbind of data.values) {
          if ('error' in varbind) {
            log.fatal(varbind.error);
            errors += 1;
          } else {
            const name = Object.keys(SNMPv2_MIB).find(
              (key) => SNMPv2_MIB[key] === varbind.oid
            );
            log.info(
              `SNMPv2-MIB::${name}.0 = ${JSON.stringify(varbind.value)}`
            );
          }
        }

        return data.values.length > 0 && errors === 0;
      },
    });
  }
);

export default SnmpPollStep;
