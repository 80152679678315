import React from "react";
import { useState, useEffect } from "react";
import { Datagrid, TextField, TopToolbar, List, FilterButton, TextInput, DateField } from "react-admin";
import CredentialsModal from "./CredentialsModal";
import DiffExpand from "./DiffExpand";

const DiffMetrics = () => {
	const [dialogState, setDialogState] = useState(false);

  useEffect(() => {}, []);

  const diffCredentialsCallback = (dialogState) => {
    setDialogState(dialogState);
  };

  const diffTokenFail = (dialogState) => {
    setDialogState(dialogState);
  };

  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
    );

  const metricFilters = [<TextInput label="Id" source="id" />];
  return (
    <>
      <CredentialsModal diffCredentialsCallback={diffCredentialsCallback} dialogState={dialogState} />
      <List basePath="metrics" resource="metrics" actions={<ListActions />} filters={metricFilters} bulkActionButtons={false}>
        <Datagrid expand={<DiffExpand diffTokenFail={diffTokenFail} />}>
          <TextField label="Metric ID" source="id" />
          <TextField label="Description" source="description" />
          <DateField label="Updated at" source="updatedAt" showTime />
        </Datagrid>
      </List>
    </>
    );
};

export default DiffMetrics;
