//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';

const DnsLookupStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: 'DNS lookup',
      action: 'lookup-dns',
      data: {},
      onResponse: (data, log) => {
        if (data.ips.length === 0) {
          log.fatal('Failed to resolve IP during DNS lookup');
          return false;
        }

        for (const ip of data.ips) {
          log.info(`ip = ${JSON.stringify(ip)}`);
        }

        if (data.staticIp) {
          log.info(
            `Configured to use static IP ${JSON.stringify(data.staticIp)}`
          );
          if (data.ips.indexOf(data.staticIp) === -1) {
            log.fatal('Failed to locate static IP during DNS lookup');
            return false;
          } else {
            return true;
          }
        }

        if (data.ips.length > 1) {
          log.fatal('Found multiple IP addresses for host');
          return false;
        }
      },
    });
  }
);

export default DnsLookupStep;
