//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';

const OnboardedStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: 'Onboarding check',
      action: 'check-onboarded',
      data: {},
      onResponse: (data, log) => {
        if (!data.found) {
          log.fatal(`Device is not onboarded`);
          return false;
        }

        for (const key of Object.keys(data.device)) {
          log.info(`${key} = ${JSON.stringify(data.device[key])}`);
        }

        log.info('Device is onboarded');
      },
    });
  }
);

export default OnboardedStep;
