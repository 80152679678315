//@ts-check
import React, { useState, useImperativeHandle, useCallback } from 'react';

import DebugStep from '../components/DebugStep';
import DisplayLogs from '../components/DisplayLogs';
import { useLogLines } from './useLogLines';
import { Status } from '../enums';

/**
 * @returns {Status}
 */
const getInitialStatus = () => Status.Idle;

/** *
 * @param {React.Ref<any>} ref
 * @param {{
 *   name: string;
 *   children?: JSX.Element | JSX.Element[];
 *   onSuccess?: () => void;
 *   onFail?: () => void;
 *  }} step
 */
export const useStep = (ref, { name, children, onSuccess, onFail }) => {
  const [status, setStatus] = useState(getInitialStatus());
  const [logLines, addLog] = useLogLines();

  const start = () => {
    addLog.info('Starting step');
    setStatus(Status.ShouldRun);
  };

  const success = useCallback(() => {
    setStatus(Status.Success);
    onSuccess && onSuccess();
  }, [onSuccess]);

  const fail = useCallback(() => {
    setStatus(Status.Fail);
    onFail && onFail();
  }, [onFail]);

  const enqueue = () => {
    if (status === Status.Running) {
      addLog.info('Step stopped');
    }
    addLog.info('Step queued');
    setStatus(Status.Queued);
  };

  const dequeue = () => {
    if (status !== Status.Queued) {
      return;
    }
    addLog.warn('Step dequeued');
    setStatus(Status.Idle);
  };

  const skipIfQueued = () => {
    if (status !== Status.Queued) {
      return;
    }
    addLog.warn('Step skipped');
    setStatus(Status.Skip);
  };

  useImperativeHandle(ref, () => ({
    startStep: start,
    enqueue,
    dequeue,
    skipIfQueued,
  }));

  return {
    component: (
      <DebugStep name={name} status={status} onClickRun={start}>
        <>
          {children}
          <DisplayLogs lines={logLines} />
        </>
      </DebugStep>
    ),
    success,
    fail,
    start: () => setStatus(Status.Running),
    shouldRun: status === Status.ShouldRun,
    isRunning: status === Status.Running,
    log: addLog,
  };
};
