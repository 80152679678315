import React from 'react';
import { Login } from 'react-admin';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#990ae3',
    }
  }
});

const useStyles = makeStyles(theme => ({
  main: {
    background: 'radial-gradient(circle, #222 0%, #000 95%)'
  },
}))

const LoginPage = props => {
  const classes = useStyles();

  return (
    <Login classes={classes} theme={customTheme} />
  );
};

export default LoginPage;