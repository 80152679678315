import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core//Button";
import { useNotify } from "react-admin";

function CredentialsModal({ diffCredentialsCallback, dialogState }) {
  const [showDialog, setShowDialog] = useState(dialogState);
  const [diffUrl, setDiffUrl] = useState("https://api.bengzhan-dev.teliacompany.net/api");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const notify = useNotify();

  useEffect(() => {
    const diffToken = localStorage.getItem("diffToken");
    const diffUrl = localStorage.getItem("diffUrl");
    if (!diffToken || !diffUrl) {
      setShowDialog(true);
    }
  }, []);

  const handleCloseClick = () => {
    setShowDialog(false);
	};

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = await authenticate(diffUrl, { username, password });
      localStorage.setItem("diffToken", token);
      localStorage.setItem("diffUrl", diffUrl);
      diffCredentialsCallback({ diffUrl });
      setLoading(false);
      setShowDialog(false);
    } catch (err) {
      setLoading(false);
      notify(`${err}`, { type: "warning" });
      setShowDialog(false);
    }
  };

  const handleDiffUrlChange = (e) => {
    setDiffUrl(e.target.value);
  };
  const handleLoginChange = (e) => {
    setUsername(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
  <>
    <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Prod credentials">
      <DialogTitle>Production environment login</DialogTitle>
      <DialogContent>
        <DialogContentText>Provide URL and credentials to access metrics from production environment.</DialogContentText>
        <TextField
          autofocus
          fullWidth
          margin="dense"
          id="diffUrl"
          label="URL"
          type="textarea"
          variant="standard"
          value={diffUrl}
          onChange={handleDiffUrlChange}
					/>
					<TextField fullWidth margin="dense" id="username" label="Username" variant="standard" value={username} onChange={handleLoginChange} />
					<TextField
            fullWidth
            margin="dense"
            id="password"
            label="Password"
            type="password"
            variant="standard"
            value={password}
            onChange={handlePasswordChange}
            />
      </DialogContent>
        <DialogActions>{loading ? <CircularProgress /> : <Button onClick={handleSubmit}>OK</Button>}</DialogActions>
			</Dialog>
		</>
	);
}

const authenticate = async (url, credentials = {}) => {
  const opts = {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: {
      "content-type": "application/json",
    },
  };

  const res = await fetch(url + "/token", opts);
  const body = (await res.json()) || {};

  if (res.status !== 200) {
    const reason = body.error || res.statusText || res.status;
    throw new Error(`Login failed: ${reason}`);
  } else {
    return body.token;
  }
};

export default CredentialsModal;
