//@ts-check
import React, { forwardRef, createContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useLocalStorage from 'use-local-storage';

import { useSimpleSuite } from './hooks/useSimpleSuite';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import OnboardedStep from './steps/OnboardedStep';
import { AuthProtocol, AuthVersion, PrivProtocol, Status } from './enums';
import Menu from './Menu';
import SnmpPollStep from './steps/SnmpPollStep';
import DnsLookupStep from './steps/DnsLookupStep';
import CustomSnmpPollStep from './steps/CustomSnmpPollStep';
import SiaStep from './steps/SiaStep';
import CompareSiaStep from './steps/CompareSiaStep';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  subHeading: {
    alignSelf: 'center',
    paddingLeft: 16,
    paddingTop: 16,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

/**
 * @returns {{
 *  host: string,
 *  authVersion: AuthVersion,
 *  communityString: string,
 *  username: string,
 *  authenticationKey: string,
 *  authenticationProtocol: AuthProtocol,
 *  privacyKey: string,
 *  privacyProtocol: PrivProtocol,
 * }}
 */
export const getInitialContext = () => ({
  host: '',
  authVersion: AuthVersion.Auto,
  communityString: '',
  username: '',
  authenticationKey: '',
  authenticationProtocol: AuthProtocol.sha,
  privacyKey: '',
  privacyProtocol: PrivProtocol.aes,
});

export const ConfigContext = createContext(getInitialContext());

const DebugDevice = forwardRef((_, ref) => {
  const classes = useStyles();
  const [config, setConfig] = useLocalStorage(
    'debug-device-config',
    getInitialContext()
  );
  const suite = useSimpleSuite(ref, {}, [
    (props) => <OnboardedStep {...props} />,
    (props) => <DnsLookupStep {...props} />,
    (props) => <SnmpPollStep {...props} />,
    (props) => <SiaStep {...props} />,
  ]);

  const startSuite = () => {
    suite.enqueue();
    suite.start();
  };

  return (
    <ConfigContext.Provider value={config}>
      <div className={classes.root}>
        <Typography className={classes.subHeading}>Debug Device</Typography>
        <Menu
          isRunning={suite.status === Status.Running}
          onChangeConfig={setConfig}
          onClickStart={startSuite}
          canStart={
            !!config.host &&
            (config.authVersion === AuthVersion.Auto ||
              (config.authVersion === AuthVersion.V2 &&
                !!config.communityString) ||
              (config.authVersion === AuthVersion.V3 &&
                !!config.username &&
                !!config.authenticationKey &&
                !!config.authenticationProtocol &&
                !!config.privacyKey &&
                !!config.privacyProtocol))
          }
        />
        {suite.status === Status.Running && <LinearProgress />}
        {suite.status === Status.Fail && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            The selected device is not working correctly. See below for what
            went wrong.
          </Alert>
        )}
        {suite.status === Status.Success && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            The selected device is working as it should.
          </Alert>
        )}
        {suite.steps}
        <Typography className={classes.subHeading}>
          Additional manual steps
        </Typography>
        <CustomSnmpPollStep />
        <CompareSiaStep />
      </div>
    </ConfigContext.Provider>
  );
});

export default DebugDevice;
