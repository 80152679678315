import * as React from "react";
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  BooleanInput,
  useRedirect,
  useNotify,
  required,
} from "react-admin";

export default (props) => {

  const redirect = useRedirect();
  const notify = useNotify();

  const handleTransform = async (data) => {
    const { file, dependencyFiles = [], resolveDeps } = data;

    const readAll = (arr) => Promise.all(arr.map(readFile));

    return {
      file: await readFile(file),
      dependencyFiles: await readAll(dependencyFiles),
      resolveDeps,
    };
  };

  const onSendMib = ({ data }) => {
    notify(`Import of ${data.id} in progress`, { type: 'success' });
    redirect('/mibs');
  };

  return (
    <Create transform={handleTransform} onSuccess={onSendMib} {...props}>
      <SimpleForm>
        <FileInput
          validate={required()}
          source="file"
          label="File to import"
          accept=".txt, .mib"
          required
        >
          <FileField source="data" title="name" />
        </FileInput>
        <FileInput
          source="dependencyFiles"
          label="Dependencies"
          accept=".txt, .mib"
          multiple
        >
          <FileField source="data" title="name" />
        </FileInput>
        <BooleanInput
          label="Resolve missing dependencies"
          source="resolveDeps"
          style={{ width: 400 }}
        />
      </SimpleForm>
    </Create>
  );
};

const readFile = ({ rawFile, ...file }) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      file.data = reader.result;
      resolve(file);
    };
    reader.onerror = reject;
    reader.readAsDataURL(rawFile);
  });
