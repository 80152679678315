//@ts-check
import { useState } from 'react';

const makeLogLine = (severity, msg) =>
  `${new Date().toISOString()} ${severity} ${msg}`;

/**
 *
 * @returns {string[]}
 */
const makeLogArray = () => [];

/**
 *
 * @returns {[string[], {
 *  info: (msg: string) => void,
 *  warn: (msg: string) => void,
 *  fatal: (msg: string) => void,
 * }]}
 */
export const useLogLines = () => {
  const [logLines, setLogLines] = useState(makeLogArray());
  return [
    logLines,
    {
      info: (msg) =>
        setLogLines((lines) => [...lines, makeLogLine('Info', msg)]),
      warn: (msg) =>
        setLogLines((lines) => [...lines, makeLogLine('Warn', msg)]),
      fatal: (msg) =>
        setLogLines((lines) => [...lines, makeLogLine('Fatal', msg)]),
    },
  ];
};
