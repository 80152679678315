//@ts-check
import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';

import { ConfigContext } from '../device-debug/DeviceDebug';
import { AuthProtocol, AuthVersion, PrivProtocol } from './enums';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  controlForm: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
}));

/**
 *
 * @param {{
 *  onChangeConfig: (config: ReturnType<typeof import('./DeviceDebug').getInitialContext>) => void
 *  onClickStart: () => void;
 *  isRunning: boolean;
 *  canStart: boolean;
 * }} props
 * @returns
 */
const Config = ({ onChangeConfig, onClickStart, isRunning, canStart }) => {
  const classes = useStyles();
  const [authVersionOpen, setAuthVersionOpen] = useState(false);
  const [authProtoOpen, setAuthProtoOpen] = useState(false);
  const [privacyProtoOpen, setPrivacyProtoOpen] = useState(false);
  const config = useContext(ConfigContext);

  const updateAuthVersion = (event) => {
    const authVersion = event.target.value;
    switch (authVersion) {
      case AuthVersion.Auto:
      case AuthVersion.V2:
      case AuthVersion.V3:
        onChangeConfig({
          ...config,
          authVersion,
        });
        break;
      default:
        throw new Error(`Unknown authVersion: ${authVersion}`);
    }
  };

  const updateAuthProtocol = (event) => {
    const authenticationProtocol = event.target.value;
    switch (authenticationProtocol) {
      case AuthProtocol.md5:
      case AuthProtocol.sha:
      case AuthProtocol.sha224:
      case AuthProtocol.sha256:
      case AuthProtocol.sha384:
      case AuthProtocol.sha512:
        onChangeConfig({
          ...config,
          authenticationProtocol,
        });
        break;
      default:
        throw new Error(
          `Unknown authenticationProtocol: ${authenticationProtocol}`
        );
    }
  };

  const updatePrivacyProtocol = (event) => {
    const privacyProtocol = event.target.value;
    switch (privacyProtocol) {
      case PrivProtocol.aes:
      case PrivProtocol.aes256b:
      case PrivProtocol.aes256r:
      case PrivProtocol.des:
        onChangeConfig({
          ...config,
          privacyProtocol,
        });
        break;
      default:
        throw new Error(`Unknown privacyProtocol: ${privacyProtocol}`);
    }
  };

  return (
    <form className={classes.controlForm} autoComplete="off">
      <TextField
        required
        label="Device Hostname"
        variant="outlined"
        value={config.host}
        error={!config.host}
        disabled={isRunning}
        style={{ width: 400 }}
        onChange={({ target: { value } }) => {
          onChangeConfig({
            ...config,
            host: value,
          });
        }}
      />
      <FormControl variant="outlined" required>
        <Select
          open={authVersionOpen}
          onClose={() => setAuthVersionOpen(false)}
          onOpen={() => setAuthVersionOpen(true)}
          value={config.authVersion}
          onChange={updateAuthVersion}
          disabled={isRunning}
        >
          <MenuItem value={AuthVersion.Auto}>Load auth from device</MenuItem>
          <MenuItem value={AuthVersion.V2}>Provide custom v2 auth</MenuItem>
          <MenuItem value={AuthVersion.V3}>Provide custom v3 auth</MenuItem>
        </Select>
      </FormControl>
      {config.authVersion === AuthVersion.V2 && (
        <TextField
          required
          label="Community string"
          variant="outlined"
          value={config.communityString}
          error={!config.communityString}
          disabled={isRunning}
          style={{ width: 300 }}
          onChange={({ target: { value } }) => {
            onChangeConfig({
              ...config,
              communityString: value,
            });
          }}
        />
      )}
      {config.authVersion === AuthVersion.V3 && (
        <>
          <TextField
            required
            label="Username"
            variant="outlined"
            value={config.username}
            error={!config.username}
            disabled={isRunning}
            style={{ width: 300 }}
            onChange={({ target: { value } }) => {
              onChangeConfig({
                ...config,
                username: value,
              });
            }}
          />
          <TextField
            required
            label="Authentication Key"
            variant="outlined"
            value={config.authenticationKey}
            error={!config.authenticationKey}
            disabled={isRunning}
            style={{ width: 300 }}
            onChange={({ target: { value } }) => {
              onChangeConfig({
                ...config,
                authenticationKey: value,
              });
            }}
          />
          <FormControl variant="outlined" required>
            <InputLabel id="auth-proto-select">
              Authentication Protocol
            </InputLabel>
            <Select
              labelId="auth-proto-select"
              label="Authentication Protocol"
              open={authProtoOpen}
              onClose={() => setAuthProtoOpen(false)}
              onOpen={() => setAuthProtoOpen(true)}
              value={config.authenticationProtocol}
              onChange={updateAuthProtocol}
              disabled={isRunning}
              style={{ width: 300 }}
            >
              <MenuItem value={AuthProtocol.md5}>HMAC-MD5</MenuItem>
              <MenuItem value={AuthProtocol.sha}>HMAC-SHA-1</MenuItem>
              <MenuItem value={AuthProtocol.sha224}>HMAC-SHA-224</MenuItem>
              <MenuItem value={AuthProtocol.sha256}>HMAC-SHA-256</MenuItem>
              <MenuItem value={AuthProtocol.sha384}>HMAC-SHA-384</MenuItem>
              <MenuItem value={AuthProtocol.sha512}>HMAC-SHA-512</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            label="Privacy Key"
            variant="outlined"
            value={config.privacyKey}
            error={!config.privacyKey}
            disabled={isRunning}
            style={{ width: 300 }}
            onChange={({ target: { value } }) => {
              onChangeConfig({
                ...config,
                privacyKey: value,
              });
            }}
          />
          <FormControl variant="outlined" required>
            <InputLabel id="privacy-proto-select">Privacy Protocol</InputLabel>
            <Select
              labelId="privacy-proto-select"
              label="Privacy Protocol"
              open={privacyProtoOpen}
              onClose={() => setPrivacyProtoOpen(false)}
              onOpen={() => setPrivacyProtoOpen(true)}
              value={config.privacyProtocol}
              onChange={updatePrivacyProtocol}
              disabled={isRunning}
              style={{ width: 300 }}
            >
              <MenuItem value={PrivProtocol.des}>CBC-DES</MenuItem>
              <MenuItem value={PrivProtocol.aes}>CFB-AES-128</MenuItem>
              <MenuItem value={PrivProtocol.aes256b}>
                CFB-AES-256 Blumenthal
              </MenuItem>
              <MenuItem value={PrivProtocol.aes256r}>
                CFB-AES-256 Reeder
              </MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      <Button
        color="primary"
        variant="outlined"
        size="large"
        onClick={onClickStart}
        disabled={isRunning || !canStart}
      >
        {isRunning ? 'Running...' : 'Start'}
      </Button>
    </form>
  );
};

export default Config;
