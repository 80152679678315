import { localStorage } from '@lolocompany/react-admin-lolo';

export default apiUrl => ({
  init (updateAuth) {
    updateAuth(localStorage.getItem('token') || null)
    window.addEventListener('localStorageItemUpdated', (e) => {
      e.value ? updateAuth(e.value) : updateAuth(null)
    }, false)
  },

  async login({ username, password }) {
    const opts = {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: {
        'content-type': 'application/json'
      }
    }

    const res = await fetch(apiUrl + '/token', opts);
    const body = (await res.json()) || {};

    if (res.status !== 200) {
      const reason = body.error || res.statusText || res.status;
      throw new Error(`Login failed: ${reason}`);
    }

    localStorage.setItem('token', body.token);
  },

  logout(params) {
    localStorage.removeItem('token');
    return Promise.resolve();
  },

  checkAuth(params) {
    return localStorage.getItem('token') ?
      Promise.resolve() :
      Promise.reject();
  },

  checkError: err => {
    if (err.status === 401) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions(params) {
    return Promise.resolve();
  }
});