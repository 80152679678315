import React from "react";
import { useStyles } from "./utils";
import { IconButton } from "@material-ui/core/";
const CustomIconButton = ({  disabled, onClick, icon }) => {
	const classes = useStyles();
	return (
		<IconButton
			color="primary"
			aria-label="move selected right"
			component="span"
			className={classes.button}
			onClick={onClick}
			disabled={disabled}
		>
			{icon}
		</IconButton>
	);
};

export default CustomIconButton;
