import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "./utils";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const SendBtnWithDialog = ({
	btnTitle,
	btnStyle,
	btnDisabled,
	dialogTitle,
	dialogBody,
	onConfirm,
	onBtnClick,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		if (typeof onBtnClick === "function") onBtnClick();
		setOpen(true);
	};

	const handleClose = async (confirmed) => {
		if (confirmed) {
			await onConfirm();
		}
		setOpen(false);
	};

	return (
		<div>
			<Button style={btnStyle} disabled={btnDisabled} variant="outlined" color="primary" onClick={handleClickOpen}>
				{btnTitle || "Button"}
			</Button>
			<Dialog
				classes={{ paper: classes.dialogPaper }}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => handleClose(false)}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{dialogTitle || "Dialog title"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">{dialogBody || null}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={() => handleClose(true)} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
