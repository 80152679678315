import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 900,
	},
	list: {
		width: 400,
		height: 600,
		backgroundColor: theme.palette.background.paper,
		overflow: "auto",
	},
	listController: {
		maxWidth: 50,
		backgroundColor: theme.palette.background.paper,
	},
	button: {
		margin: theme.spacing(0.5, 0),
	},
	heading: {
		alignSelf: "center",
		paddingLeft: 0,
		paddingTop: 16,
		paddingBottom: 16,
		fontSize: theme.typography.pxToRem(20),
		fontWeight: theme.typography.fontWeightRegular,
	},
	dialogPaper: {
		minHeight: "50vh",
		maxHeight: "50vh",
	},
}));

const not = (a, b) => {
	return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
	return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a, b) => {
	return [...a, ...not(b, a)];
};

export { useStyles, not, intersection, union };
