import * as ral from '@lolocompany/react-admin-lolo';

export default apiUrl => {
  const dataProvider = ral.dataProvider(apiUrl);

  dataProvider.getToken = async () => {
    return localStorage.getItem('token');
  };

  return dataProvider;
}
