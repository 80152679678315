import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SaveContextProvider, useDataProvider } from 'react-admin';
import MonacoEditor, { monaco } from '@monaco-editor/react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { ListMetricVersions } from './ListVersions';

const EDITOR_TAB = 0;
const VERSIONS_TAB = 1;

function MibScriptField(props) {
  const { id } = useParams();
  const { formData, onChange } = props;
  const modelRef = useRef();
  const dataProvider = useDataProvider();
  const [editorRef, setEditorRef] = useState();
  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    return () => (
      modelRef.current &&
      modelRef.current.dispose()
    );
  }, []);

  async function editorDidMount(getValue, editor) {
    const monacoInstance = await monaco.init();
    await addLibs(dataProvider, monacoInstance);

    modelRef.current = createModel(
      editor,
      monacoInstance,
      formData.src
    );

    editor.onDidChangeModelContent(
      () => onChange({ src: getValue() })
    );

    setEditorRef(editor);
  }


  const updateEditorContent = (newValue) => {
    editorRef.setValue(newValue);
    setActiveTab(0);
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };


  return (
    <SaveContextProvider>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#990ae3"
              }
            }}>
            <Tab label="Code" id="metric-code" aria-controls='metric-code' />
            <Tab label="Version history" id="metric-versions" aria-controls='metric-versions' />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          hidden={activeTab !== EDITOR_TAB}
        >
          <MonacoEditor
            height={600}
            language="typescript"
            theme="vs-light"
            value={formData.src}
            editorDidMount={editorDidMount}
            options={{
              tabSize: 2,
              minimap: {
                enabled: false
              }
            }}
          />
        </div>
        <div
          role="tabpanel"
          hidden={activeTab !== VERSIONS_TAB}
        >
          <ListMetricVersions metricId={decodeURIComponent(id)} onClick={updateEditorContent}/>
        </div>
      </Box>
    </SaveContextProvider>
  );
}

const addLibs = async (dataProvider, monaco) => {
  const { typescriptDefaults } = monaco.languages.typescript;
  const items = await dataProvider.sendRequest('/types').then(res => res.data);

  for (const { content, file } of items) {
    typescriptDefaults.addExtraLib(content, file);
  }
};

const createModel = (editor, monaco, value) => {
  const model = monaco.editor.createModel(
    value,
    'typescript',
    monaco.Uri.parse('file:///main.tsx')
  );

  editor.setModel(null)
  editor.setModel(model);

  return model;
}

export default MibScriptField;
