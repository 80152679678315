//@ts-check
import React, { useEffect, useContext } from 'react';

import { useStep } from './useStep';
import { useRequest } from './useRequest';
import { ConfigContext } from '../DeviceDebug';
import { AuthVersion } from '../enums';
/**
 * @typedef {{
 *   info: (msg: string) => void,
 *   warn: (msg: string) => void,
 *   fatal: (msg: string) => void,
 * }} Log
 *
 * @param {React.Ref<any>} ref
 * @param {{
 *  name: string,
 *  action: import('./useRequest').Action,
 *  data: Record<string, any>
 *  onResponse: (resp: any, log: Log) => void | boolean | PromiseLike<void | boolean>,
 *  children?: JSX.Element[]
 *  onSuccess?: () => void,
 *  onFail?: () => void,
 * }} props
 */
export const useActionStep = (
  ref,
  { name, action, data, onSuccess, onFail, onResponse, children }
) => {
  const config = useContext(ConfigContext);
  const sendRequest = useRequest(action, {
    host: config.host.trim(),
    auth: {
      [AuthVersion.Auto]: {
        version: AuthVersion.Auto,
      },
      [AuthVersion.V2]: {
        version: AuthVersion.V2,
        community: config.communityString.trim(),
      },
      [AuthVersion.V3]: {
        version: AuthVersion.V3,
        username: config.username.trim(),
        authenticationKey: config.authenticationKey.trim(),
        authenticationProtocol: config.authenticationProtocol,
        privacyKey: config.privacyKey.trim(),
        privacyProtocol: config.privacyProtocol,
      },
    }[config.authVersion],
    ...data,
  });
  const step = useStep(ref, {
    name: name,
    children: <>{children}</>,
    onFail,
    onSuccess,
  });

  useEffect(() => {
    if (!step.shouldRun) {
      return;
    }
    step.log.info(`${name} starting`);
    step.start();

    sendRequest().then(async (resp) => {
      if (resp.error) {
        step.log.fatal(resp.error);
        step.fail();
        return;
      }

      if (resp.data.error) {
        step.log.fatal(`${name} failed: ${resp.data.error}`);
        step.fail();
        return;
      }

      try {
        const isOk = await onResponse(resp.data, step.log);

        if (isOk === false) {
          step.log.fatal(`${name} failed`);
          step.fail();
        } else {
          step.log.info(`${name} successful`);
          step.success();
        }
      } catch (err) {
        step.log.fatal(`${name} failed: ${err}`);
        step.fail();
        return;
      }
    });
  }, [name, onResponse, sendRequest, step, step.shouldRun]);

  return step.component;
};
