import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Form from "@rjsf/material-ui";

import { Title, useDataProvider } from 'react-admin';
import { timeFormat } from 'd3-time-format';
import { stringify } from 'query-string';
import * as rc from 'recharts';

/*
 * Main Component
 */

const Graph = () => {
  const dataProvider = useDataProvider();

  const [ metricIds, setMetricIds ] = useState([]);
  const [ deviceIds, setDeviceIds ] = useState([]);

  const [ schema, setSchema ] = useState({});
  const [ filter, setFilter ] = useState({});
  const [ data, setData ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  // Load metrics and devices on mount

  useEffect(() => {
    Promise.all([
      dataProvider.sendRequest('/metrics?pick[]=id&limit=500'),
      dataProvider.sendRequest('/devices?pick[]=id&limit=500')
    ]).then(res => {
      const { metrics } = res[0].data;
      const { devices } = res[1].data;

      setMetricIds([ ...metrics.map(m => m.id), '@debug' ]);
      setDeviceIds(devices.map(d => d.id));

      if (!metrics[0] || !devices[0]) return;

      setFilter(filter => ({
        ...filter,
        metricId: metrics[0].id,
        deviceId: devices[0].id
      }));
    });
  }, [ dataProvider ]);

  // Rebuild schema on metrics and devices change

  useEffect(() => {
    const schema = buildSchema(metricIds, deviceIds);
    setSchema(schema);
  }, [ metricIds, deviceIds ]);

  // Load datapoints on filter change

  useEffect(() => {
    const { metricId, ...rest } = filter;

    if (metricId && rest.deviceId && rest.gte && rest.lte) {
      const url = `/metrics/${metricId}/values?${stringify(rest)}`;
      setLoading(true);

      dataProvider.sendRequest(url).then(res => {
        console.log('loaded', res.data.length, 'datapoints');
        setData(res.data);
        setLoading(false);
      });
    }
  }, [ dataProvider, filter ]);

  return (
    <Card>
      <Title title="Graph" />
      <CardContent>
        <Form
          schema={schema}
          formData={filter}
          onChange={ev => setFilter(ev.formData)}
          ObjectFieldTemplate={ObjectFieldTemplate}
          >{' '}
        </Form>
        <br/>
        <LineChart
          data={data}
          loading={loading}
          xMin={new Date(filter.gte).getTime()}
          xMax={new Date(filter.lte).getTime()}
        />
      </CardContent>
    </Card>
  );
};

const buildSchema = (metricIds = [], deviceIds = []) => {
  const toEpoch = Date.now();
  const fromEpoch = toEpoch - 60 * 60 * 1000;

  const schema = {
    properties: {
      metricId: {
        type: 'string',
        title: 'Metric',
        enum: metricIds
      },
      deviceId: {
        type: 'string',
        title: 'Device',
        enum: deviceIds
      },
      gte: {
        type: 'string',
        title: 'From',
        format: 'date-time',
        default: shortIso(fromEpoch)
      },
      lte: {
        type: 'string',
        title: 'To',
        format: 'date-time',
        default: shortIso(toEpoch)
      },
    }
  };

  return schema;
};

/*
 * LineChart Component
 */

const LineChart = ({ data, loading, xMin, xMax }) => {
  if (loading) return <p>Loading...</p>;
  if (!data || !data.length) return null;

  const dataKeys = Object.keys(data.reduce((memo, datapoint) => {
    for (const [ k, v ] of Object.entries(datapoint)) {
      if (k === 'timestamp' || isNaN(v)) continue;
      memo[k] = null;
    }
    return memo;
  }, {}));

  return (
    <rc.ResponsiveContainer width='100%' height={550}>
      <rc.LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
        <rc.CartesianGrid strokeDasharray="3 3" />
        <rc.XAxis
          dataKey="timestamp"
          tickFormatter={timeFormat('%H:%M')}
          //domain={[ xMin, xMax ]}
        />
        <rc.YAxis />
        <rc.Tooltip
          labelFormatter={ts => (
            <p style={{marginTop: 0}}>
              {shortIso(ts)}
            </p>
          )}
          itemSorter={item => item.dataKey}
        />
        <rc.Legend />
        { dataKeys.map(
          dataKey => (
            <rc.Line
              type="basis"
              dataKey={dataKey}
              stroke={randomColor()}
              isAnimationActive={false}
              dot={false}
            />
          )
        )}
      </rc.LineChart>
    </rc.ResponsiveContainer>
  );
}

const shortIso = epoch => new Date(epoch).toISOString().replace(/\.[0-9]{3}/, '');

const ObjectFieldTemplate = props => (
  <Grid container spacing={3}>
    {props.properties.map(element =>
      <Grid key={element.name} item md={3} xs={12}>
        {element.content}
      </Grid>
    )}
  </Grid>
);

const randomColor = () => {
  return '#' + Math.random().toString(16)
    .substr(-6)
    .replace(/d/ig, 'c')
    .replace(/e/ig, 'd')
    .replace(/f/ig, 'e');
};

export default Graph