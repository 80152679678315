import React from 'react';
import * as ra from 'react-admin';
import { Grid } from '@material-ui/core';

import { LoloList } from '@lolocompany/react-admin-lolo';
import ScheduleIcon from '@material-ui/icons/AccessTimeOutlined';

const ExpandPanel = props => {
	return (
    <Grid container style={{marginBottom: 10}}>
      <Grid item xs={1} align='center'>
        <ScheduleIcon style={{marginTop: 7}} color='action'/>
      </Grid>
      <Grid item xs={11}>
      <ra.ReferenceManyField
        reference='schedules'
        target='metricId'
        perPage={100}
        filter={{ enabled: true }}
        {...props}
        >
        <ra.Datagrid rowClick='edit'>
          <ra.ReferenceField
            label='Device Type'
            source='deviceTypeId'
            reference='device-types'
            >
            <ra.TextField source="name" />
          </ra.ReferenceField>
          <ra.TextField source='interval' />
          <ra.DateField label='Last Poll' source="state.updatedEpoch" showTime />
        </ra.Datagrid>
      </ra.ReferenceManyField>
      </Grid>
    </Grid>
	);
};

const ListMetrics = props => (
	<LoloList
		expand={ExpandPanel} {...props}
	/>
);

export default ListMetrics;