//@ts-check
import React from 'react';
import loader from '@monaco-editor/loader';
import Editor from '@monaco-editor/react';

import * as monacoLogLanguage from '../util/monacoLogLanguage';

loader.init().then((monaco) => monacoLogLanguage.register(monaco));

/**
 *
 * @param {{
 *  lines: string[]
 * }} param0
 */
const DisplayLogs = ({ lines }) => {
  return (
    <Editor
      height="30vh"
      options={{
        readOnly: true,
        lineNumbers: 'off',
        minimap: {
          enabled: false,
        },
      }}
      value={lines.join('\n')}
      language="log"
      theme="logview"
    />
  );
};

export default DisplayLogs;
