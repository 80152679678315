//@ts-check
import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from './Accordion';
import { StatusIndicator } from './StatusIndicator';
import { Status } from '../enums';

const useStyles = makeStyles((theme) => ({
  detailsRoot: {
    display: 'block !important',
  },
  heading: {
    alignSelf: 'center',
    paddingLeft: 16,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

/**
 *
 * @param {{
 *  children: JSX.Element | JSX.Element[],
 *  status: Status,
 *  onClickRun: () => void,
 *  name: string,
 * }} params
 */
const DebugStep = ({ name, children, status, onClickRun }) => {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StatusIndicator status={status} />
        <Typography className={classes.heading}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.detailsRoot}>
        {children}
      </AccordionDetails>
      <AccordionActions>
        <Button
          size="small"
          color="primary"
          // eslint-disable-next-line no-undef
          disabled={status === Status.Running || status === Status.Queued}
          onClick={onClickRun}
        >
          {status === Status.Queued && 'Queued...'}
          {status === Status.Running && 'Running...'}
          {(status === Status.Idle || status === Status.Skip) && 'Run step'}
          {(status === Status.Success || status === Status.Fail) &&
            'Rerun step'}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default DebugStep;
